import {createClient, configureChains} from 'wagmi';
import {
  mainnet,
  polygon,
  arbitrum,
  optimism,
  avalanche,
  bsc,
  bscTestnet,
  polygonMumbai,
} from 'wagmi/chains';
import {jsonRpcProvider} from 'wagmi/providers/jsonRpc';
import {WalletConnectConnector} from 'wagmi/connectors/walletConnect';
import {MetaMaskConnector} from 'wagmi/connectors/metaMask';
import {CoinbaseWalletConnector} from 'wagmi/connectors/coinbaseWallet';
import {InjectedConnector} from 'wagmi/connectors/injected';
import {SafeConnector} from '@gnosis.pm/safe-apps-wagmi';
import {getRpcUrl} from './ankrRpc';

const {chains, provider} = configureChains(
  [
    mainnet,
    polygon,
    arbitrum,
    avalanche,
    bsc,
    bscTestnet,
    polygonMumbai,
    optimism,
  ],
  [
    jsonRpcProvider({
      rpc: pChain => {
        const url = getRpcUrl(pChain.id);
        return url ? {http: url} : null;
      },
      priority: 0,
      stallTimeout: 1000,
    }),
  ]
);

const client = createClient({
  connectors: [
    new MetaMaskConnector({chains}),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new CoinbaseWalletConnector({
      options: {
        appName: 'Paywong',
      },
      chains,
    }),
    new InjectedConnector({
      chains,
      options: {
        name: 'Injected',
        shimDisconnect: true,
      },
    }),
    // @ts-ignore
    new SafeConnector({chains}),
  ],
  provider,
  autoConnect: true,
});

export {chains, client};
